<template>
  <div style="z-index:2000;">

    <div class="header" id="top" v-if="$device.isDesktopOrTablet">
      <div class="container">
        <span v-if="isMainPage()" class="logo-link">
          <BrandLogo class="logo"/>
        </span>
        <nuxt-link v-else :to="localePath('/')" class="logo-link" :aria-label="$t('to_homepage')">
          <BrandLogo class="logo"/>
        </nuxt-link>

        <div class="catalog-btn"
          @click.prevent="toggleCat"
          v-bind:class="{ open: isOpen }"
          v-if="$device.isDesktop"
        >
          <div class="catalog-icon">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <span>{{ $t('catalog') }}</span>
        </div>
        <div class="catalog-btn"
          @click="toggleCat"
          v-bind:class="{ open: isOpen }"
          v-else
          >
          <!--
            @touchstart="isOpen = !isOpen"
            v-touch:tap="toggleCat"
            v-touch:tap="isOpen = !isOpen"
            @mousedown="touchEvent"
          -->
          <div class="catalog-icon">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <span>{{ $t('catalog') }}</span>
        </div>

        <div class="search">
          <products-search-input/>
        </div>

        <div class="x-file" @mouseover="showTooltip = !showTooltip" @mouseleave="showTooltip = false" v-if="!$noCartMode()">
          <nuxt-link v-if="localePath('/search/oem/') !== $route.path" :to="localePath('/search/oem/')" :aria-label="$t('search')">
            <i class="svg-icon file icon-brand"></i></nuxt-link>
          <span v-else><i class="svg-icon file icon-brand"></i></span>

          <Transition name="fade">
            <div v-if="showTooltip" class="tooltip">
              <span>{{ $t('search_by_oem') }}</span>
            </div>
          </Transition>
        </div>

        <header-item v-for="(item, idx) in headerMenu" :navi-item="item"
                     :key="`header-menu-${idx}`"/>


        <div class="link-tooltip" v-show="favouriteProductsExists"
             @mouseover="showTooltipFavourites = !showTooltipFavourites"
             @mouseleave="showTooltipFavourites = false">
          <nuxt-link :to="localePath('/products/favourites/')"
                     :aria-label="$t('favourites')"><i
              class="svg-icon star"></i>

            <Transition name="fade">
              <div v-if="showTooltipFavourites" class="tooltip">
                <span>{{ $t('favourites') }}</span>
              </div>
            </Transition>
          </nuxt-link>
        </div>

        <template v-if="!$noCartMode()">
          <div class="link-tooltip" @mouseover="showTooltipPriceRequest = !showTooltipPriceRequest"
              @mouseleave="showTooltipPriceRequest = false"
          >

            <nuxt-link
              :to="localePath('/price-request/')"
              :aria-label="$t('price_requests')"
              class="requests-link"
              v-if="localePath('/price-request/') !== $route.path"
            >
              <i class="svg-icon preorder icon-brand"></i>
              <span class="circle" v-show="priceRequestExists"></span>
            </nuxt-link>
            <span
              :aria-label="$t('price_requests')"
              class="requests-link"
              v-else
            >
              <i class="svg-icon preorder icon-brand"></i>
              <span class="circle" v-show="priceRequestExists"></span>
            </span>

            <Transition name="fade" v-if="!$noCartMode()">
              <div v-if="showTooltipPriceRequest" class="tooltip">
                <span>{{ $t('price_requests') }}</span>
              </div>
            </Transition>
          </div>

          <div class="link-tooltip" :class="{'cart-disabled': vendor === 'wellfar' && !$auth.loggedIn}"
               @mouseover="showTooltipCart = !showTooltipCart" @mouseleave="showTooltipCart = false"
          >
            <nuxt-link :to="localePath('/cart/')" class="cart-link" :aria-label="$t('cart')">
              <i class="svg-icon cart-icon"></i>
              <span class="circle" v-show="cartExists"></span>

              <Transition name="fade">
                <div v-if="showTooltipCart" class="tooltip">
                  <span>{{ $t('cart') }}</span>
                </div>
              </Transition>
            </nuxt-link>
          </div>

          <div class="link-tooltip" v-show="$auth.loggedIn" @mouseover="showTooltipProfile = !showTooltipProfile"
              @mouseleave="showTooltipProfile = false"
          >
            <nuxt-link :to="localePath('/profile/orders/')" :aria-label="$t('orders')">
              <i class="svg-icon person"></i>

              <Transition name="fade">
                <div v-if="showTooltipProfile" class="tooltip">
                  <span>{{ $t('profile') }}</span>
                </div>
              </Transition>
            </nuxt-link>
          </div>

          <div class="link-tooltip" v-show="$auth.loggedIn" @mouseover="showTooltipExit = !showTooltipExit"
              @mouseleave="showTooltipExit = false">
            <a class="exit-link" href="#"
              @click.prevent="logout" :aria-label="$t('logout')"><i class="svg-icon exit"></i>

              <Transition name="fade">
                <div v-if="showTooltipExit" class="tooltip">
                  <span>{{ $t('logout') }}</span>
                </div>
              </Transition>
            </a>
          </div>

          <div class="link-tooltip" v-show="!$auth.loggedIn" @mouseover="showTooltipLogin = !showTooltipLogin"
              @mouseleave="showTooltipLogin = false">
            <nuxt-link :to="localePath('/login/')" :aria-label="$t('login')">
              <i class="svg-icon login-icon"></i>

              <Transition name="fade">
                <div v-if="showTooltipLogin" class="tooltip">
                  <span>{{ $t('login') }}</span>
                </div>
              </Transition>
            </nuxt-link>
          </div>
        </template>

        <languages-panel v-if="!$noCartMode()"/>
      </div>
    </div>

    <div class="header header-mobile" id="top" v-else>
      <div class="container">
        <nuxt-link :to="localePath('/')" class="logo-link" :aria-label="$t('to_homepage')">
          <BrandLogoMobile />
        </nuxt-link>

        <div class="catalog-btn"
            @touchstart="toggleCat"
            :class="{ 'open': isOpen }"
        >
          <div class="catalog-icon">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <header-item-mobile v-for="(item, idx) in headerMenu" :navi-item="item"
                            :key="`header-menu-${idx}`" @close-tree="closeTree()"/>

        <template v-if="!$noCartMode()">

          <template v-if="vendor === 'wellfar' && !$auth.loggedIn">
            <nuxt-link
                :to="localePath('/price-request/')"
                :aria-label="$t('price_requests')"
                class="requests-link"
                v-if="localePath('/price-request/') !== $route.path"
            >
              <i class="svg-icon preorder icon-brand"></i>
              <span class="circle" v-show="priceRequestExists"></span>
            </nuxt-link>
            <span
                :aria-label="$t('price_requests')"
                class="requests-link"
                v-else
            >
              <i class="svg-icon preorder icon-brand"></i>
              <span class="circle" v-show="priceRequestExists"></span>
            </span>
          </template>


          <nuxt-link :to="localePath('/cart/')" class="cart-link"
                     :class="{'cart-disabled': vendor === 'wellfar' && !$auth.loggedIn}" :aria-label="$t('cart')"><i
              class="svg-icon cart-icon"></i><span
              class="circle" v-show="cartExists"></span>
          </nuxt-link>
          <nuxt-link v-show="$auth.loggedIn" :to="localePath('/profile/orders/')" :aria-label="$t('orders')"
                     class="person-link"><i
            class="svg-icon person"></i>
          </nuxt-link>
          <a class="exit-link" v-show="$auth.loggedIn" href="#"
            @click.prevent="logout" :aria-label="$t('logout')"><i class="svg-icon exit"></i></a>
          <nuxt-link v-show="!$auth.loggedIn" :to="localePath('/login/')" :aria-label="$t('login')"
                     class="login-link"><i
            class="svg-icon login-icon"></i>
          </nuxt-link>
        </template>

        <template v-for="item in headerMenu" v-if="!$noCartMode()">
          <a v-if="item.phone" class="phone-link"
             :href="item.link_external" :aria-label="$t('phone')"><i class="svg-icon phone"></i></a>
        </template>

        <languages-panel v-if="!$noCartMode()"/>

      </div>
    </div>

    <!-- <transition name="fade" @after-enter="treeVisible = true" @after-leave="treeVisible = false"> -->
    <tree v-show="isOpen" v-click-outside="closeCat" @toggle-cat="toggleCat"/>
  </div>
</template>

<script>

import {Header} from '@/models/menu';

import HeaderItem from '@/components/header-item';
import HeaderItemMobile from '@/components/header-item-mobile';
import LanguagesPanel from '@/components/languages-panel';
import ProductsSearchInput from '@/components/catalog/products-search-input';

export default {
  name: 'Header',
  components: {
    'BrandLogo': () => import(`~/assets/${process.env.VENDOR}/icons/logo.svg?inline`),
    'BrandLogoMobile': () => import(`~/assets/${process.env.VENDOR}/icons/logo-mobile.svg?inline`),
    'Tree': () => import(`@/components/${process.env.VENDOR}/tree`),
    HeaderItem,
    HeaderItemMobile,
    LanguagesPanel,
    ProductsSearchInput,
  },
  data() {
    return {
      isOpen: false,
      headerMenu: [],
      treeVisible: false,
      showTooltip: false,
      showTooltipPriceRequest: false,
      showTooltipFavourites: false,
      showTooltipCart: false,
      showTooltipProfile: false,
      showTooltipExit: false,
      showTooltipLogin: false,
    }
  },
  watch: {
    isOpen: {
      handler(value) {
        this.$store.dispatch(value ? 'hideScrollbar' : 'showScrollbar');
      },
      immediate: true,
    },
    '$i18n.locale': {
      handler(value) {
        Header.config({count_loading: true}).get().then((response) => this.headerMenu = response);
      },
    },
    '$route.path': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.isOpen = false;
        }
      },
    }
  },
  computed: {
    cartExists() {
      return this.$store.getters.cartExists.count;
    },
    priceRequestExists() {
      return this.$store.getters.priceRequestExists;
    },
    favouriteProductsExists() {
      return this.$auth.loggedIn && this.$store.getters.favouriteProductsExists;
    },
    vendor() {
      return process.env.VENDOR
    },
  },
  async fetch() {
    this.headerMenu = await Header.config({count_loading: true}).get();
    await this.$store.dispatch('setCartExists');
    await this.$store.dispatch('setPriceRequestExists');
    await this.$store.dispatch('setFavouriteProductsExists');
  },
  methods: {
    isMainPage() {
      return this.$route.path === this.localePath('/');
    },
    logout() {
      this.$auth.$storage.removeCookie('remember_expires');
      this.$auth.logout().then(() => {
        this.$store.dispatch('setCartExists');
        this.$store.dispatch('setPriceRequestExists');
      });
    },
    toggleCat() {
      this.isOpen = !this.isOpen;
    },
    closeCat() {
      //TODO: ?
      if (this.treeVisible) {
        this.isOpen = false;
      }
    },
    closeTree() {
      //TODO: ?
      this.isOpen = false
    },
    touchEvent() {
      //TODO: ?
      if (!this.$device.isMobile) {
        // this.isOpen = !this.isOpen;
      }
    },
  },
}
</script>

<i18n lang="yaml">
ru:
  catalog: 'Каталог'
  search_by_oem: 'Загрузка списком'
  login: 'Вход'
  logout: 'Выход'
  phone: 'Телефон'
en:
  catalog: 'Catalog'
  search_by_oem: 'Upload by list'
  login: 'Login'
  logout: 'Logout'
  phone: 'Phone'
</i18n>
