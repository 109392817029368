<i18n lang="yaml">
ru:
  menu: 'Меню'
en:
  menu: 'Menu'
</i18n>

<template>
  <div>
    <div v-if="naviItem.show_child_pages && naviItem.mobile_show"
         class="menu menu-c header-item-mobile"
        v-bind:class="{'show-menu': showMenuMobile}"
        v-click-outside="closeMenuMobile"
    >
      <div class="dots-wrapper" @click="closeTreeMobile">
        <i class="svg-icon dots"></i>
      </div>

      <Transition name="fade">
        <div v-if="showMenuMobile" class="container header-menu">
          <div>
            <div class="menu-header-close">
              <h1>{{ $t('menu') }}</h1>
              <div @click="closeMenuMobile">
                <i class="svg-icon cross"></i>
              </div>
            </div>

            <template v-for="page in pagesChildren">
              <span v-if="localePath(`/${naviItem.link_page}/${page.slug}/`) === $route.path" @click="closeMenu">
                {{ page.title }}
              </span>
              <a @click="activeMenu=page.id" v-else-if="page.children.length > 0" class="has-submenu">
                <span>{{ page.title }}</span>
                <i class="svg-icon arrow-mobile"></i>
              </a>
              <nuxt-link v-else :to="localePath(`/${naviItem.link_page}/${page.slug}/`)">
                {{ page.title }}
              </nuxt-link>
            </template>
          </div>
        </div>
      </Transition>

      <transition name="fade" v-for="page0 in pagesChildren">
        <div class="menu header-menu" v-if="page0.children.length > 0" v-show="activeMenu===page0.id">
          <div class="">
            <a class="back menu-header-close" @click="activeMenu=null">
              <i class="svg-icon arrow-mobile"></i>
              <!-- {{ $t('back') }} -->
              {{ page0.title }}
            </a>
            <nuxt-link @click="showMenuMobile=false;activeMenu=null;" :to="localePath('/' + page0.slug + '/' + page.slug)" class="page-link" v-for="page in page0.children">
              <span @click="showMenuMobile=false;activeMenu=null;">{{ page.title }}</span>
            </nuxt-link>
          </div>
        </div>
      </transition>
    </div>

    <div v-else-if="naviItem.header_submenu.length && naviItem.mobile_show"
         @click="closeTreeMobile" class="menu menu-c header-item-mobile"
         v-bind:class="{'show-menu': showMenuMobile}"
         v-click-outside="closeMenuMobile"
    >
      <i class="svg-icon dots"></i>
      
      <Transition name="fade">
        <div v-if="showMenuMobile" class="container header-menu">
          <div>
            <div class="menu-header-close">
              <h1>{{ $t('menu') }}</h1>
              <div @click="closeMenu">
                <i class="svg-icon cross"></i>
              </div>
            </div>
            <site-link v-for="item in naviItem.header_submenu" :key="item.id" v-if="item.mobile_show" :link-item="item"/>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import SiteLink from '@/components/site-link';

export default {
  components: {
    SiteLink,
  },
  props: ['naviItem'],
  data() {
    return {
      showMenu: false,
      showMenuMobile: false,
      pagesChildren: [],
      activeMenu: null
    }
  },
  mounted() {
    if (this.naviItem.show_child_pages) {
      this.$axios.$get('/pages/find/', {params: {html_path: this.naviItem.link_page}})
          .then((response) =>
              this.$axios.$get('/menu-child-pages/', {params: {page_id: response.id}})
                  .then((response) => {
                    this.pagesChildren = response
                  })
          )
    }
  },
  methods: {
    closeMenu() {
      // if (this.showMenu) {
      //   this.showMenu = !this.showMenu;
      // }
      this.showMenu = false;
    },
    closeMenuMobile() {
      // if (this.showMenuMobile) {
      //   this.showMenuMobile = !this.showMenuMobile;
      // }
      this.showMenuMobile = false;
    },
    closeTree() {
      // this.showMenu = false  
      this.showMenu = !this.showMenu
      this.$emit('close-tree');
    },
    closeTreeMobile() {
      this.showMenuMobile = !this.showMenuMobile
      // this.showMenuMobile = false
      this.$emit('close-tree');
    },
  },
}
</script>
